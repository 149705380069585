.App{ font-family: "Roboto", sans-serif;
}

body,
#root,
.Body {
    margin: 0px;
    min-height: 100vh;
}


.App {
    background-image: url("peeps.png"),
    linear-gradient(#00d9, #00d9ff, #205db3);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center center;
    justify-content: center;
    color: rgb(248, 249, 250);
    text-shadow: 1px 1px 2px black;
    font-weight: bold;
    text-align: top center;
    height: 100vh;
    width: 100vw;
    margin: 0vh auto 0 auto;
    overflow: auto;
    position: relative;
    background-position: center center;
}
  