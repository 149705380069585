.App{ font-family: "Roboto", sans-serif;
}

body,
#root,
.Body {
    margin: 0px;
    min-height: 100vh;
}


.App {
    background-image: url(/static/media/peeps.06624456.png),
    linear-gradient(#00d9, #00d9ff, #205db3);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center center;
    justify-content: center;
    color: rgb(248, 249, 250);
    text-shadow: 1px 1px 2px black;
    font-weight: bold;
    text-align: top center;
    height: 100vh;
    width: 100vw;
    margin: 0vh auto 0 auto;
    overflow: auto;
    position: relative;
    background-position: center center;
}
  
.home {
    max-width: 100%;
    text-align: center;
    margin: 0;
    padding: 0 
}

.h2 {
    font: Roboto, sans-serif;
    font-size: 48;
    text-shadow: 1px 1px 2px black;
    position:relative;
    margin-top: 0px; 
}

.address {
    font-size: 18px;
    margin: 0;
    text-shadow: 1px 1px 2px black;
}

.peep {
    font-size: 24;
    text-shadow: 1px 1px 2px black;
    font-weight: bold;

}

.connect {      
    border: 1px solid black;
    background:rgb(229, 228, 228);
    color: rgba(76, 0, 255, 0.895);
    font-size: 14px;
    padding: 10px 10px;
    border-radius: 6px;
    width: 20%;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.btnp {
        border: 1px solid black;
        background:rgb(229, 228, 228);
        color: rgba(34, 34, 181);
        font-size: 14px;
        padding: 10px 10px;
        border-radius: 6px;
        width: 20%;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
}

#walletAddressContainer {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    background-color: linear-gradient(#00d9, #00d9ff, #205db3);
    text-shadow: 1px 1px 2px black;
  }

  .cause {
    font-size: 48;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
  }

@media (max-width: 1000px) {
    

}
